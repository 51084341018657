import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { InputGroupText,InputGroup, Row, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';

export default function Paciente(props){
    const edoGlobal = useContext(AppContext);
    let [apePat, setApePat] = useState('');
    let [apeMat, setApeMat] = useState('');
    let [nombre, setNombre] = useState('');
    let [dir, setDir] = useState('');
    let [col, setCol] = useState('');
    let [cp, setCP] = useState('');
    let [tel, setTel] = useState('');
    let [email, setEmail] = useState('');
    let [sexo, setSexo] = useState("?");
    let [imss, setImss] = useState("?");
    let [fecNac, setFecNac] = useState(new Date().yyyymmdd('-'));

    useEffect(() => {
        setApePat(props.paciente.ApePat);
        setApeMat(props.paciente.ApeMat);
        setNombre(props.paciente.Nombre);
        setDir(props.paciente.Direccion);
        setCol(props.paciente.Colonia);
        setCP(props.paciente.CP);
        setTel(props.paciente.Telefono);
        setEmail(props.paciente.Email);
        setSexo(props.paciente.Sexo);
        setImss(props.paciente.RegImss);
        setFecNac(props.paciente.FechaNacStr);
    }, [props.paciente]);

    async function guardar() {
        if (apePat.length < 4) {
            Swal.fire('Atencion', 'Debe indicar el apellido paterno del paciente', 'error');
            return;
        }
        if (nombre.length < 3) {
            Swal.fire('Atencion', 'Debe indicar el nombre del paciente', 'error');
            return;
        }
        if (sexo === "?") {
            Swal.fire('Atencion', 'Debe indicar el sexo del paciente', 'error');
            return;
        }
        const dato = {
            Id:props.paciente.Id, ApePat:apePat, ApeMat:apeMat, Nombre : nombre,
            FechaNacStr:fecNac, RegImss:imss, Direccion:dir, Telefono:tel,
            Email:email, Sexo:sexo, CP:cp, Colonia:col
        }
        console.log(dato);
        let url = `${edoGlobal.urlws}/paciente/wsguardar?db=${props.empresa}`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res.substring(0,3) != "Err") {
            Swal.fire('Atencion', 'Datos guardados', 'success');
            props.cerrar(Number(res));
        }
        else
            Swal.fire('Atencion', res, 'error');
    }

    return (
        <div>
            <Row>
                <div className='col-4'>
                    <FormGroup >
                        <span>Apellido Pat.</span>
                        <Input size="sm" value={apePat} onChange={e => setApePat(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-4'>
                    <FormGroup >
                        <span>Apellido Mat.</span>
                        <Input size="sm" value={apeMat} onChange={e => setApeMat(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-4'>
                    <FormGroup >
                        <span>Nombre</span>
                        <Input size="sm" value={nombre} onChange={e => setNombre(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-3'>
                    <FormGroup >
                        <span>Fecha Nac.</span>
                        <Input size="sm" type="date" value={fecNac} onChange={e => setFecNac(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>Sexo</span>
                        <Input size="sm" type="select" value={sexo} onChange={e => setSexo(e.target.value)}>
                            <option value={"?"}>Indique</option>
                            <option value={"M"}>Mujer</option>
                            <option value={"H"}>Hombre</option>
                        </Input>
                    </FormGroup>
                </div>
                <div className='col-3'>
                    <FormGroup >
                        <span>Reg.IMSS</span>
                        <Input size="sm" value={imss} onChange={e => setImss(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-6'>
                    <FormGroup >
                        <span>Direccion</span>
                        <Input size="sm" value={dir} onChange={e => setDir(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup >
                        <span>CP</span>
                        <Input size="sm" value={cp} onChange={e => setCP(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-4'>
                    <FormGroup >
                        <span>Colonia</span>
                        <Input size="sm" value={col} onChange={e => setCol(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-3'>
                    <FormGroup >
                        <span>Telefono</span>
                        <Input size="sm" value={tel} onChange={e => setTel(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-5'>
                    <FormGroup >
                        <span>Email</span>
                        <Input size="sm" value={email} onChange={e => setEmail(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-2'></div>
                <div className='col-5'>
                    <Button size="sm" color="success" onClick={guardar}>Aceptar</Button>
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    <Button size="sm" color="danger" onClick={()=>props.cerrar(0)}>Cancelar</Button>
                </div>
            </Row>
        </div> 
    )
}