import React, { useDebugValue } from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Spinner, FormGroup, CardBody, Button, Table, Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup, ModalHeader, ModalFooter} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';

export const Tickets = (props) => {
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [empresa, setEmpresa] = useState(2);
    let [almacen, setAlmacen] = useState(1);
    let [datos, setDatos] = useState([]);
    let [clave, setClave] = useState("TK");
    let [claves, setClaves] = useState([]);
    let [fechaIni, setFechaIni] = useState(new Date().yyyymmdd('-'));
    let [fechaFin, setFechaFin] = useState(new Date().yyyymmdd('-'));
    let [regimenes, setRegimenes] = useState([]);
    let [metodos, setMetodos] = useState([]);
    let [formas, setFormas] = useState([]);
    let [usos, setUsos] = useState([]);
    let [regimen, setRegimen] = useState("");
    let [metodo, setMetodo] = useState("");
    let [forma, setForma] = useState("");
    let [uso, setUso] = useState("");
    let [modalPago, setModalPago] = useState(false);
    let [indice, setIndice] = useState(0);
    let [nombre, setNombre] = useState('');
    let [calle, setCalle] = useState('');
    let [orientacion, setOrientacion] = useState('');
    let [numeroExt, setNumeroExt] = useState('');
    let [numeroInt, setNumeroInt] = useState('');
    let [colonia, setColonia] = useState('');
    let [rfc, setRfc] = useState('');
    let [ciudad, setCiudad] = useState('');
    let [municipio, setMunicipio] = useState('');
    let [estado, setEstado] = useState('');
    let [pais, setPais] = useState('');
    let [cp, setCP] = useState('');
    let [email, setEmail] = useState('');
    let [titulo, setTitulo] = useState('');
    let [pacienteEnFactura,setPacienteEnFactura] = useState(true);

    useEffect(() => {
        setEmpresa(edoGlobal.usuario.Empresa);
        setAlmacen(edoGlobal.usuario.Almacen);
        cargarCatalogos();
    }, []);

    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/factura/tipos/${empresa}`;
        let resp = await fetch(url);
        let datos = await resp.json();
        url = `${edoGlobal.urlws}/factura/formasPago`;
        resp = await fetch(url);
        const formas = await resp.json();
        url = `${edoGlobal.urlws}/factura/metodosPago`;
        resp = await fetch(url);
        const metodos = await resp.json();
        url = `${edoGlobal.urlws}/factura/usosCfdi`;
        resp = await fetch(url);
        const usos = await resp.json();
        url = `${edoGlobal.urlws}/factura/regimenes`;
        resp = await fetch(url);
        const reg = await resp.json();

        setMetodos(metodos);
        setFormas(formas);
        setUsos(usos);
        setRegimenes(reg);
        setClaves(datos);
        setCargando(false);
    }
    async function cargarDatos(){
        let url = `${edoGlobal.urlws}/factura/listadoTikets?db=${empresa}&alm=${almacen}&cve=${clave}&fecIni=${fechaIni}&fecFin=${fechaFin}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDatos(datos);
        setCargando(false);
    }
    function imprimir(i){
        let url = `${edoGlobal.urlws}/factura/tiketPDF?db=${empresa}&alm=${almacen}&cve=${clave}&num=${datos[i].Numero}`;
        if(clave == "FA" || clave == "FC")
           url = `${edoGlobal.urlws}/factura/facturaPDF?db=${empresa}&alm=${almacen}&cve=${clave}&num=${datos[i].Numero}`;
        window.open(url, '', '');
    }
    async function facturar(i){
        let cte = datos[i].Cte;
        setCargando(true);
        let url = `${edoGlobal.urlws}/cliente/carga/${cte}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setTitulo(datos[i].Numero + " // " + datos[i].Pac);
        setNombre(dato.Nombre);
        setRegimen(dato.Regimen);
        setCalle(dato.Calle);
        setOrientacion(dato.Orientacion);
        setNumeroExt(dato.NumeroExt);
        setNumeroInt(dato.NumeroInt);
        setColonia(dato.Colonia);
        setRfc(dato.Rfc);
        setCiudad(dato.Ciudad);
        setMunicipio(dato.Municipio);
        setEstado(dato.Estado);
        setPais(dato.Pais);
        setCP(dato.CP);
        setCargando(false);
        setIndice(i);
        setMetodo(datos[i].metodo);
        setForma(datos[i].forma);
        setUso(datos[i].uso);
        setModalPago(true);
    }
    async function timbrar(){
        let i = indice;
        let dir = calle;
        let pob = ciudad;
        if(colonia != "")
           dir = dir + " Col. " + colonia;
        if(municipio != "")
           pob = pob + ", " + municipio;
        if(estado != "")
           pob = pob + ", " + estado;
        let dato = {
            emp:empresa, alm:almacen, num:datos[i].Numero, usu:edoGlobal.usuario.Id,
            cve:clave,nom:nombre, dir, pob,
            cp, metodo, forma, uso, rfc, regimen, email,
            pacEnPDF : pacienteEnFactura ? "S":"N"
        }
        let url = `${edoGlobal.urlws}/factura/wsfacturarTK`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0, 3) !== "Err"){
            await Swal.fire('Atencion','se registro la factura ' + res,'success');
            setModalPago(false);
            cargarDatos();
        }
        else
           Swal.fire('Atencion',res,'error');
    }
    async function enviar(i){
        if(clave == "FA" || clave == "FC"){
            enviarEmail(i);
            return;
        }
        let num = datos[i].Numero;
        const { value: email } = await Swal.fire({
            title: 'Enviar tiket ' + num + ' por email',
            input: 'email',
            inputLabel: 'direccion de email del paciente',
            inputPlaceholder: 'direccion de email del paciente'
          })          
          if (email) {
            let url = `${edoGlobal.urlws}/factura/tiketEmail?db=${empresa}&alm=${almacen}&cve=${clave}&num=${num}&email=${email}`;
            setCargando(true);
            let resp = await fetch(url);
            setCargando(false);
            let res = await resp.text();
            if(res == "ok")
               Swal.fire('Atencion','se envio el email a ' + email,'success');
            else
               Swal.fire('Atencion',res,'error');
        }
    }
    async function enviarEmail(i){
        let num = datos[i].Numero;
        const { value: email } = await Swal.fire({
            title: 'Enviar factura ' + clave + '-' + num + ' por email',
            input: 'email',
            inputLabel: 'direccion de email del paciente',
            inputPlaceholder: 'direccion de email del paciente'
          })          
          if (email) {
            let url = `${edoGlobal.urlws}/factura/facturaEmail?db=${empresa}&alm=${almacen}&cve=${clave}&num=${num}&email=${email}`;
            setCargando(true);
            let resp = await fetch(url);
            setCargando(false);
            let res = await resp.text();
            if(res == "ok")
               Swal.fire('Atencion','se envio el email a ' + email,'success');
            else
               Swal.fire('Atencion',res,'error');
        }
    }
    let cfdi = clave == "FA" || clave == "FC" || clave == "NC" || clave == "PA";
    return(
        <div>
            <table>
                <tr>
                    <td>Tipo</td>
                    <td>
                        <Input size="sm" type="select" value={clave} onChange={(e) => setClave(e.target.value)}>
                            <option value={""}>Especifique</option>
                            {claves.map((o, i) =>
                                <option value={o.I} key={i}>{o.N}</option>
                            )}
                        </Input>
                    </td>
                    <td>&nbsp;&nbsp;Del dia</td>
                    <td><Input size="sm" type="date" value={fechaIni} onChange={(e) => setFechaIni(e.target.value)} /></td>
                    <td>&nbsp;&nbsp;al dia</td>
                    <td><Input size="sm" type="date" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} /></td>
                    <td>&nbsp;&nbsp;</td>
                    <td>&nbsp;&nbsp;</td>
                    <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarDatos}><img src="/imagenes/ok.png" title="cargar datos" /></Button></td>
                </tr>
            </table>
            <div style={{ height: 450, overflow: 'auto' }}>
                <Table size="sm" hover striped>
                    <thead>
                        <tr>
                            <th className='numeroCeldaCh'>Folio</th>
                            <th className='celdaCodigo'>Fecha</th>
                            <th>Cliente</th>
                            <th>Paciente</th>
                            <th className='numeroCeldaMed'>Importe</th>
                            <th celdaCodigo>Cancelada</th>
                            <th celdaCodigo>{cfdi ? "Timbrada":"Facturada"}</th>
                            <th className='celdaCh'></th>
                            <th className='celdaCh'></th>
                            <th className='celdaCh'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {datos.map((o, i) => <tr key={i}>
                            <td className='numeroCeldaCh'>{o.Numero}</td>
                            <td>{o.Fec}</td>
                            <td>{o.NomCte}</td>
                            <td>{o.Pac}</td>
                            <td className='numeroCeldaMed'>{o.Total.formato()}</td>
                            <td><input type="checkbox" checked={o.Cancelada} /></td>
                            <td>
                                <input type="checkbox" checked={o.Facturada} />
                                <span>&nbsp;&nbsp;</span>
                                {!cfdi && !o.Cancelada && !o.Facturada && <img onClick={() => facturar(i)} src="/imagenes/xml.png" title="facturar" />}
                                {!cfdi && o.Facturada && <span>{o.fact}</span>}
                            </td>
                            <td>{o.T}</td>
                            <td><img onClick={() => imprimir(i)} src="/imagenes/printer.png" title="imprimir" /></td>
                            <td><img onClick={() => enviar(i)} src="/imagenes/email.png" title="enviar email" /></td>
                            <td><img src="/imagenes/whatsapp.png" title="enviar por whatsApp" /></td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>
            <Modal size="lg" isOpen={modalPago} toggle={()=> setModalPago(false)}>
                <ModalHeader>
                    <h6>Facturar {clave}-{titulo}</h6>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='col-9'>
                            <FormGroup >
                                <span>Nombre</span>
                                <Input size="sm" value={nombre} onChange={e => setNombre(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col-3'>
                            <FormGroup >
                                <span>R.F.C.</span>
                                <Input size="sm" value={rfc} onChange={e => setRfc(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Direccion</span>
                                <Input size="sm" value={calle} onChange={e => setCalle(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col-2'>
                            <FormGroup >
                                <span>C.P.</span>
                                <Input size="sm" value={cp} onChange={e => setCP(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Colonia</span>
                                <Input size="sm" value={colonia} onChange={e => setColonia(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Ciudad</span>
                                <Input size="sm" value={ciudad} onChange={e => setCiudad(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Municipio</span>
                                <Input size="sm" value={municipio} onChange={e => setMunicipio(e.target.value)} />
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Estado</span>
                                <Input size="sm" value={estado} onChange={e => setEstado(e.target.value)} />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Regimen</span>
                                <Input size="sm" type="select" value={regimen} onChange={e => setRegimen(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {regimenes.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className='col-4'>
                            <FormGroup >
                                <span>Uso</span>
                                <Input size="sm" type="select" value={uso} onChange={e => setUso(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {usos.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className='col'>
                            <FormGroup >
                                <span>Metodo de pago</span>
                                <Input size="sm" type="select" value={metodo} onChange={e => setMetodo(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {metodos.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className='col'>
                            <FormGroup >
                                <span>Forma de pago</span>
                                <Input size="sm" type="select" value={forma} onChange={e => setForma(e.target.value)}>
                                    <option value={""}>Especifique</option>
                                    {formas.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                    <div className='col'>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <Input type="checkbox" checked={pacienteEnFactura} onChange={(e) => setPacienteEnFactura(e.target.checked)} />
                                        <span>Imprimir nombre del paciente en factura</span>
                    </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className='col'>
                    <Button color="success" size="sm" outline onClick={timbrar}>Aceptar</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button color="danger" size="sm" outline onClick={()=>setModalPago(false)}>Cancelar</Button>
                    </div>
                </ModalFooter>
            </Modal>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
    )
}