import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Row, Input, Spinner, CardHeader, CardBody, Button, Table, Container, Accordion, AccordionBody, AccordionHeader, AccordionItem, Modal, ModalBody, InputGroup} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Pacientes = (props) => {
    const edoGlobal = useContext(AppContext);
    let [cargando,setCargando] = useState(false);
    let [usuario, setUsuario] = useState(1);
    let [empresa, setEmpresa] = useState(props.empresa);
    let [datos, setDatos] = useState([]);
    let [apePatB, setApePatB] = useState("");
    let [apeMatB, setApeMatB] = useState("");
    let [nombreB, setNombreB] = useState("");
    let [aliasB, setAliasB] = useState("");
    let [capturando, setCapturando] = useState(false);
    let [id, setId] = useState(0);
    let [apePat, setApePat] = useState("");
    let [apeMat, setApeMat] = useState("");
    let [nombre, setNombre] = useState("");
    let [edad, setEdad] = useState(0);
    let [imss, setImss] = useState("");
    let [fechaNac, setFechaNac] = useState(new Date().yyyymmdd('-'));
    let [sexo, setSexo] = useState("?");
    let [telefono, setTelefono] = useState("");
    let [direccion, setDireccion] = useState("");
    let [cp, setCP] = useState("");
    let [colonia, setColonia] = useState("");
    let [email, setEmail] = useState("");

    useEffect(() => {
        setEmpresa(props.empresa);
        if(props.pac != null){
            apePatB = props.pac.ApePat;
            apeMatB = props.pac.ApeMat;
            nombreB = props.pac.Nombre;
            setApePatB(props.pac.ApePat);
            setApeMatB(props.pac.ApeMat);
            setNombreB(props.pac.Nombre);    
            cargarDatos();
        }
    }, []);
/*
    async function cargarCatalogos(){
        let url = `${edoGlobal.urlws}/iphCivica/instituciones`;
        let resp = await fetch(url);
        let datos = await resp.json();
        setIntituciones(datos);
        setCargando(false);
    }
*/
    async function cargarDatos(){
        let url = `${edoGlobal.urlws}/paciente/listaweb/${empresa}?apePat=${apePatB}&apeMat=${apeMatB}&nom=${nombreB}`;
        setCargando(true);
        let resp = await fetch(url);
        let datos = await resp.json();
        setDatos(datos);
        setCargando(false);
    }
    function nuevo(){
        editar(0);
    }
    async function editar(i){
        let url = `${edoGlobal.urlws}/paciente/cargar/${i}?db=${empresa}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setId(i);
        setApePat(d.ApePat);
        setApeMat(d.ApeMat);
        setNombre(d.Nombre);
        setFechaNac(d.FechaNacStr);
        setEdad(d.Edad);
        setImss(d.RegImss);
        setDireccion(d.Direccion);
        setTelefono(d.Telefono);
        setEmail(d.Email);
        setSexo(d.Sexo);
        setCP(d.CP);
        setColonia(d.Colonia);
        setCargando(false);
        setCapturando(true);
    }
    async function seleccionar(i){
        let url = `${edoGlobal.urlws}/paciente/cargar/${i}?db=${empresa}`;
        setCargando(true);
        let resp = await fetch(url);
        let d = await resp.json();
        setCargando(false);
        props.pacSeleccionado(d);
    }
    function guardar(){
        if(sexo == "" || sexo == "?"){
            Swal.fire('Atencion','falta indicar el sexo del paciente','error');
            return;
        }
        if(nombre == "" ){
            Swal.fire('Atencion','falta indicar el nombre del paciente','error');
            return;
        }
        if(apePat == "" ){
            Swal.fire('Atencion','falta indicar el apellido del paciente','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                guardarB();
        });
    }
    async function guardarB(){
        let dato = {
            Id: id, ApePat:apePat, ApeMat:apeMat,Nombre:nombre,
            FechaNacStr:fechaNac, Sexo:sexo, RegImss:imss,
            Telefono:telefono,Direccion:direccion, Email:email,
            CP:cp, Sexo:sexo, Colonia:colonia, 
            Usuario:usuario, Empresa:empresa
        }
        let url = `${edoGlobal.urlws}/paciente/wsguardar?db=${empresa}`;
        setCargando(true);
        let json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST',
            body: json
          });
        const res = await resp.text();
        setCargando(false);
        if(res.substring(0,2) !== "Er"){
            setCapturando(false);
            if(props.sel)
               seleccionar(res);
            else
               cargarDatos();
        }else
           Swal.fire('Atencion',res,'error');
    }

    const estiloListado = { display: capturando ? 'none' : 'inline' };
    const estiloCaptura = { display: !capturando ? 'none' : 'inline' };

    return(
        <div>
            <div style={estiloListado}>
                <table>
                    <tr>
                        <td><Input size="sm" placeholder='Ape.Pat.' value={apePatB} onChange={(e) => setApePatB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='Ape.Mat.' value={apeMatB} onChange={(e) => setApeMatB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td><Input size="sm" placeholder='nombre' value={nombreB} onChange={(e) => setNombreB(e.target.value)} /></td>
                        <td>&nbsp;&nbsp;</td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={cargarDatos}><img src="/imagenes/ok.png" title="cargar datos" /></Button></td>
                        <td>&nbsp;<Button size="sm" color="success" outline onClick={nuevo}><img src="/imagenes/add.png" title="nuevo dato" /></Button></td>
                    </tr>
                </table>
                <div style={{ height: 450, overflow: 'auto' }}>
                    <Table size="sm" hover striped>
                        <thead>
                            <tr>
                                <th className='numeroCeldaCh'>ID</th>
                                <th>Ap.Pat.</th>
                                <th>Ap.Mat.</th>
                                <th>Nombre</th>
                                <th className='celdaCodigo'>Telefono</th>
                                <th className='celdaCodigo'>Fec.Nac.</th>
                                <td className='celdaOpcion'></td>
                                <td className='celdaOpcion'></td>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.map((o, i) => <tr key={i}>
                                <td  className='numeroCeldaCh'>{o.I}</td>
                                <td>{o.P}</td>
                                <td>{o.M}</td>
                                <td>{o.N}</td>
                                <td>{o.T}</td>
                                <td>{o.F}</td>
                                <td><img onClick={() => editar(o.I)} src="/imagenes/edit.png" title="editar" /></td>
                                <td>
                                    {props.sel ? <img onClick={() => seleccionar(o.I)} src="/imagenes/ok.png" title="seleccionar" /> : null}
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div style={estiloCaptura}>
                <Row>
                    <div className="col">
                        Apellido Pat.
                        <Input size="sm" value={apePat} onChange={(e) => setApePat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Apellido Mat.
                        <Input size="sm" value={apeMat} onChange={(e) => setApeMat(e.target.value)} />
                    </div> 
                    <div className="col">
                        Nombre
                        <Input size="sm" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col-2">
                        Reg.IMSS
                        <Input size="sm" value={imss} onChange={(e) => setImss(e.target.value)} />
                    </div> 
                    <div className="col-3">
                        Fecha Nac.
                        <Input size="sm" type="date" value={fechaNac} onChange={(e) => setFechaNac(e.target.value)} />
                    </div> 
                    <div className="col-1">
                        Edad
                        <Input size="sm" value={edad}/>
                    </div> 
                    <div className="col-2">
                        Sexo
                        <Input size="sm" type="select" value={sexo} onChange={(e) => setSexo(e.target.value)} >
                            <option value={'?'}>Especifique</option>
                            <option value={'M'}>Mujer</option>
                            <option value={'H'}>Hombre</option>
                        </Input>
                    </div> 
                    <div className="col-2">
                        C.P.
                        <Input size="sm" value={cp} onChange={(e) => setCP(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className="col">
                        Direccion
                        <Input size="sm" value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                    </div> 
                    <div className="col-3">
                        Colonia
                        <Input size="sm" value={colonia} onChange={(e) => setColonia(e.target.value)} />
                    </div> 
                    <div className="col-2">
                        Telefono
                        <Input size="sm" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                    </div> 
                    <div className="col-3">
                        Email
                        <Input size="sm" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div> 
                </Row>
                <Row>
                    <div className='col-4'>
                        <br/>
                        <Button size="sm" color="success" outline onClick={guardar}><img src="/imagenes/disk.png" />  Guardar</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="danger" outline onClick={()=>setCapturando(false)}> Cancelar </Button>
                    </div>
                </Row>
            </div>
            {cargando ? (
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
            ) : null}
        </div>
    )
}