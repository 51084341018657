import React from 'react';
import {useState, useContext, useEffect} from 'react';
import Calendar from 'react-calendar/dist/umd/Calendar';
import { Popover, PopoverBody, Button, Spinner, Row, Table, Modal, ModalHeader, ModalBody, Input, FormGroup, InputGroup, InputGroupAddon, Card, CardHeader, CardBody } from 'reactstrap';
import { AppContext } from './AppContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tabla } from './Tabla';
import { Pacientes } from './Pacientes';
import {Buscador, BuscadorArticulos} from './Buscador';
import { Panel } from './Panel';

export const Citas = () => {
    const edoGlobal = useContext(AppContext);
    let [almacen, setAlmacen] = useState(1);
    let [modalPac,setModalPac] = useState(false);
    let [fecha,setFecha] = useState(new Date());
    let [citas,setCitas] = useState([]);
    let [tiposPac,setTiposPac] = useState([]);
    let [idCita,setIdCita] = useState(0);
    let [deptos,setDeptos] = useState([]);
    let [horas,setHoras] = useState([]);
    let [depto, setDepto] = useState(0);
    let [descrip,setDescrip] = useState("");
    let [pacientes,setPacientes] = useState([]);
    let [cargando,setCargando] = useState(false);
    let [editando,setEditando] = useState(false);
    let [selPac,setSelPac] = useState(false);
    let [hora,setHora] = useState("");
    let [paciente,setPaciente] = useState({});
    let [tipoPac, setTipoPac] = useState(-1);
    let [nomTipoPac,setNomTipoPac] = useState("");
    let [idCte, setIdCte] = useState(0);
    let [idPac, setIdPac] = useState(0);
    let [orden,setOrden] = useState("");
    let [tipo,setTipo] = useState("P");
    let [texto,setTexto] = useState("");
    let [nombreCte, setNombreCte] = useState("PUBLICO EN GENERAL");
    let [nombrePac,setNombrePac] = useState("");
    let [codigo, setCodigo] = useState("");
    let [nombreEst,setNombreEst] = useState("");
    let [nombre,setNombre] = useState("");
    let [apePat,setApePat] = useState("");
    let [apeMat,setApeMat] = useState("");
    let [popoverArtiOpen,setPopoverArtiOpen] = useState(false);
    let [popoverMedOpen,setPopoverMedOpen] = useState(false);
    let [popoverCteOpen,setPopoverCteOpen] = useState(false);
    let [medico, setMedico] = useState({});
    let [idMed, setIdMed] = useState(0);
    let [nombreMed, setNombreMed] = useState("");
    let [citaMov, setCitaMov] = useState(null);
    let [fechaMov, setFechaMov] = useState("");
    let [empresa,setEmpresa] = useState(0);
    let [lista, setLista] = useState("A");
    let [cliente, setCliente] = useState({});

    useEffect(() => {
        (async () => {
            empresa = edoGlobal.usuario.Empresa;
            setEmpresa(edoGlobal.usuario.Empresa);
            cargarDeptos();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            cargarCitas(horas);
        })();
    }, [fecha]);

    useEffect(() => {
        (async () => {
            cargarHoras();
        })();
    }, [depto]);

    async function cargarDeptos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/home/departamentos/${empresa}`;
        let resp = await fetch(url);
        let dep = await resp.json();
        url = `${edoGlobal.urlws}/factura/tiposPaciente/${empresa}`;
        resp = await fetch(url);
        const tp = await resp.json();
        setCargando(false);
        setDeptos(dep);
        setTiposPac(tp);
        if(dep.length != 0){
           depto = dep[0].I;
           setDepto(depto);
        }
    }

    async function cargarHoras(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/home/citasHorario/${empresa}?dep=${depto}`;
        let resp = await fetch(url);
        const hr = await resp.json();
        setHoras(hr);
        setCargando(false);
        cargarCitas(hr);
    }
    async function cargarCitas(hrs){
        setCargando(true);
        let alm = almacen;
        let hr = "";
        let url = `${edoGlobal.urlws}/home/citasDepto/${empresa}?alm=${alm}&fecha=${fecha.yyyymmdd('-')}&depto=${depto}`;
        let resp = await fetch(url);
        const citas = await resp.json();
        for(let h = 0; h < hrs.length; h++){
            hrs[h].I = 0;
            hrs[h].N = "";
            hrs[h].E = "";
            hrs[h].TipoPac = -1;
            hrs[h].NomTipoPac = "";
            hrs[h].T = "";
            hrs[h].R = "";
        }
        for(let c = 0; c < citas.length; c++){
            hr = citas[c].H;
            for(let h = 0; h < hrs.length; h++){
                if(hr == hrs[h].H){
                    hrs[h].I = citas[c].I;
                    hrs[h].N = citas[c].P;
                    hrs[h].E = citas[c].E;
                    hrs[h].M = citas[c].M;
                    hrs[h].R = citas[c].R;
                    hrs[h].Cod = citas[c].Cod;
                    hrs[h].Pac = citas[c].Pac;
                    hrs[h].Med = citas[c].Med;
                    hrs[h].TipoPac = citas[c].Tipo;
                    hrs[h].NomTipoPac = citas[c].T;
                    hrs[h].T = citas[c].Txt;
                }
            }
        }
        setEditando(false);
        setCitas(citas);
        setCargando(false);
    }
    function pacSeleccionado(dato){
        setPaciente(dato);
        setIdPac(dato.Id);
        setNombrePac(dato.Nombre + " " + dato.ApePat + " " + dato.ApeMat);
        setModalPac(false);
    }
    function articuloSel(a){
        codigo = a.C;
        cargarArticulo();       
    }
    function clienteSel(c){
        idCte = c.C;
        cargarCliente();       
    }
    async function cargarCliente() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/cliente/carga/${idCte}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Empresa == 0){
            setNombreCte("No registrado");
            return;
        }
        lista = dato.ListaDMA;
        setCliente(dato);
        setIdCte(idCte);
        setLista(dato.ListaDMA);
        setNombreCte(dato.Nombre);
    }
    async function cargarMedico() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/medico/cargar/${idMed}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Clave == 0){
            setNombreMed("No registrado");
            return;
        }
        setMedico(dato);
        setIdMed(idMed);
        setNombreMed(dato.NombreCompleto);
    }
    function medicoSel(c){
        idMed = c.I;
        cargarMedico();       
    }
    async function cargarArticulo() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/articulo/cargarPrecio/${codigo}?db=${empresa}&alm=${almacen}&lista=${lista}&medico=${idMed}&cliente=${idCte}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Empresa == 0){
            setNombreEst("No registrado");
            return;
        }
        if(dato.Depto != depto){
            Swal.fire('Atencion','Este estudio es de otro departamento','error');
            return;
        }
        if(dato.PDescto > 0)
           dato.Precio = dato.Precio - Math.round(dato.Precio * dato.PDescto / 100,0);
        Swal.fire(dato.Codigo,dato.Nombre + " // Precio: " + dato.Precio,'info');
        setCodigo(codigo);
        setNombreEst(dato.Nombre + " // Precio: " + dato.Precio);
        setDescrip(dato.Descripcion);
    }
    async function cargarPaciente() {
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/cargar/${idPac}?db=${empresa}`;
        let resp = await fetch(url);
        const dato = await resp.json();
        setCargando(false);
        if(dato.Id == 0){
            setNombrePac("No registrado");
            return;
        }
        setPaciente(dato);
        setIdPac(idPac);
        setNombrePac(dato.Nombre + " " + dato.ApePat + " " + dato.ApeMat);
    }
    async function cargarPacientes(){
        if (nombre + apePat + apeMat === "")
            return;
        setCargando(true);
        let url = `${edoGlobal.urlws}/paciente/lista?nutriologo=${edoGlobal.usuario.Id}&apePat=${apePat}&apeMat=${apeMat}&nombre=${nombre}`;
        let resp = await fetch(url);
        const pacientes = await resp.json();
        setCargando(false);
        setPacientes(pacientes);
    }

    /*
    function seleccionar(i){
        const nom = pacientes[i].N + ' ' + pacientes[i].P + ' ' + pacientes[i].M;
        const MySwal = withReactContent(Swal)
        setSelPac(false);
        MySwal.fire({
            title: 'Confirme',
            text: "¿ agendar a " + nom + " a las " + hora + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                grabaCita(i);
            }
          })        
    }
    */
    async function guardar(){
        if(tipoPac < 0){
            Swal.fire('Atencion','No se ha indicado el tipo de paciente','error');
            return;
        }
        const dato = {
            Id : idCita, Almacen : almacen, //edoGlobal.usuario.Almacen,
            Empresa : empresa, Usuario : edoGlobal.usuario.Id,
            Fecha : fecha.yyyymmdd('-'),
            Depto : depto, Cliente: idCte, Paciente:idPac,
            Hora:hora, Codigo : codigo, Medico:idMed,
            Orden : orden, Tipo : tipo, Texto : texto,
            TipoPac : tipoPac, NomTipoPac : nomTipoPac
        }
        let url = `${edoGlobal.urlws}/home/wsguardarCita`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        setCargando(false);
        if(res === "ok"){
            setEditando(false);
            cargarCitas(horas)
        }
        else{
            Swal.fire('Atencion',res,'error');
            cargarCitas(horas)
        }
    }
    function nueva(i){
        if(citaMov != null){
            reagendarCita(i);
            return;
        }
        setIdCita(0);
        setTipoPac(-1);
        setNomTipoPac("");
        setIdMed(0);
        setIdPac(0);
        setDescrip("");
        setCodigo("");
        setHora(horas[i].H);
        setNombrePac("");
        setNombreEst("");
        setNombreMed("");
        setTexto("");
        setEditando(true);
    }
    function reagendarCita(i){
        let hr = horas[i].H;
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ reagendar la cita de " + citaMov.P + " del " + fechaMov.yyyymmdd('-') + " a las " + citaMov.H + " al " + fecha.yyyymmdd('-') + " a las " + hr + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                reagendarCitaB(hr);
            }
          })        
    }
    async function reagendarCitaB(hr){
        let url = `${edoGlobal.urlws}/home/moverCita/${citaMov.I}?db=${empresa}&fecha=${fecha.yyyymmdd('-')}&hora=${hr}`;
        const resp = await fetch(url,{ method: 'POST'});
        const res = await resp.text();
        if(res === "ok"){
            setCitaMov(null);
            cargarCitas(horas);
        }
        else
            Swal.fire('Atencion',res,'error');
    }

    function editar(i){
        codigo = horas[i].Cod;
        setIdCita(horas[i].I);
        setDescrip("");
        setIdMed(horas[i].Med);
        setIdPac(horas[i].Pac);
        setTipoPac(horas[i].TipoPac)
        setCodigo(horas[i].Cod);
        setHora(horas[i].H);
        setNombrePac(horas[i].N);
        setNombreEst(horas[i].E);
        setNombreMed(horas[i].M);
        setTexto(horas[i].T);
        setEditando(true);
        cargarArticulo();
        //setSelPac(true);
    }
    function moverCita(i){
        setFechaMov(fecha);
        for(let c = 0; c < citas.length; c++)
           if(horas[i].I == citas[c].I){
              setCitaMov(citas[c]);
              break;
           }
    }
    function eliminar(i){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ quitar la cita de " + horas[i].N + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                eliminarB(horas[i].I);
            }
          })        
    }
    async function eliminarB(i){
        let url = `${edoGlobal.urlws}/home/eliminarCita/${i}?db=${empresa}`;
        const resp = await fetch(url,{ method: 'POST'});
        const res = await resp.text();
        if(res === "ok"){
            cargarCitas(horas);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    function cambiarTipoPac(tp){
        setTipoPac(tp);
        if(tp == 4 || tp == 5)
           capturarNomTipoPac(tp == 4 ? "IMSS":"ISSSTE");
        else
           setNomTipoPac("");
    }
    async function capturarNomTipoPac(inst){
        const { value: clinica } = await Swal.fire({
            title: 'Numero de clinica',
            input: 'text',
            inputLabel: 'Numero de clinica',
            inputPlaceholder: ''
          })
          if (clinica)
             setNomTipoPac(inst + " " + clinica);
          else
             setNomTipoPac("");
    }
    async function cambiarEmpresa(e){
        empresa = e;
        await setEmpresa(e);
        await cargarDeptos();
        await cargarCitas(horas);
    }
    const estiloListado = { display: editando ? 'none' : 'inline' };
    const estiloCaptura = { display: !editando ? 'none' : 'inline' };
    return(
        <div>
            <Row>
                <div className='col-3'>
                    {edoGlobal.usuario.CallCenter && <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Empresa</span>
                                <Input size="sm" type="select" className="form-control form-control-sm" name="emp" value={empresa} onChange={e => cambiarEmpresa(e.target.value)} >
                                    <option value={0}>Seleccione</option>
                                    <option value={2}>D.M.I.</option>
                                    <option value={1}>Analizate</option>
                                </Input>
                            </div>
                        </div>
                    </div>
                    }
                    <Calendar onChange={setFecha} value={fecha}/>
                    { citaMov != null &&
                    <div>
                        <br/>
                        <br/>
                            <Card>
                                <CardHeader>
                                    <h6>Reagendando cita</h6>
                                </CardHeader>
                                <CardBody>
                                    <span>{fechaMov.yyyymmdd('-')}</span><br />
                                    <span>{citaMov.H}</span><br />
                                    <span>{citaMov.P}</span><br />
                                    <span>{citaMov.E}</span><br />
                                    <span>{citaMov.Txt}</span><br />
                                    <br />
                                    <Button size="sm" color="danger" onClick={() => setCitaMov(null)} >Cancelar</Button>
                                </CardBody>
                            </Card>
                    </div>
                    }
                </div>
                <div className='col'>
                    <table>
                        <tr>
                            <td>
                               <h4>{fecha.yyyymmdd('-')}</h4>
                            </td>
                            <td>
                                <Input size="sm" type="select" value={depto} onChange={e => setDepto(e.target.value)}>
                                    <option value={-1}>Especifique</option>
                                    {deptos.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                                </Input>
                            </td>
                            <td><Button outline size="sm" color="success"><img src="/imagenes/ok.png" title="actualizar" /></Button></td>
                        </tr>
                    </table>
                    <div style={estiloListado}>
                        <Table size="sm" striped hover>
                            <thead>
                                <tr>
                                    <th className='numeroCeldaCh'>Hora</th>
                                    <th className='celdaNombre'>Paciente</th>
                                    <th className='celda150'>Tipo</th>
                                    <th>Estudio</th>
                                    <th style={{ width: '30px' }}></th>
                                    <th style={{ width: '30px' }}></th>
                                    <th>Tiket</th>
                                </tr>
                            </thead>
                            <tbody>
                                {horas.map((o, i) =>
                                    <tr key={i}>
                                        <td>{o.H}</td>
                                        <td>{o.N}</td>
                                        <td>{o.NomTipoPac}</td>
                                        <td>{o.E}</td>
                                        <td>
                                            {o.R !== "" ? null :
                                            <div>
                                              {o.I === 0 ? <img src="/imagenes/add.png" alt="nueva" title="nueva cita" onClick={() => nueva(i)} /> : <img src="/imagenes/edit.png" alt="editar" title="editar cita" onClick={() => editar(i)} />}
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            {o.I === 0 || o.R !== "" ? null  : <img onClick={() => eliminar(i)} src="/imagenes/menos.png" alt="eliminar" title="borrar cita" />}
                                        </td>
                                        <td>
                                            {o.R !== "" || o.I === 0 ? o.R  : <img onClick={() => moverCita(i)} src="/imagenes/citas.png" alt="mover" title="mover cita" />}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div style={estiloCaptura}>
                        <Panel titulo={"Cita para las " + hora}>
                            <table >
                                <tr>
                                    <td ><span>Paciente</span></td>
                                    <td style={{ width: '100px' }}>
                                        <InputGroup>
                                            <Input size="sm" type="text" value={idPac} onChange={e => setIdPac(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarPaciente(); }} />
                                            <InputGroupAddon addonType="append">
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" onClick={() => setModalPac(true)}>
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td style={{ width: '700px' }}>
                                        <Input size="sm" value={nombrePac} />
                                    </td>
                                </tr>
                                <tr>
                                    <td ><span>Cliente</span></td>
                                    <td style={{ width: '100px' }}>
                                        <InputGroup>
                                            <Input size="sm" type="text" value={idCte} onChange={e => setIdCte(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarCliente(); }} />
                                            <InputGroupAddon addonType="append">
                                            &nbsp;
                                            <Button size="sm" outline color="secondary" id="btnBuscarCteCita">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={popoverCteOpen} target="btnBuscarCteCita" toggle={() => setPopoverCteOpen(!popoverCteOpen)} >
                                                <PopoverBody>
                                                    <Buscador campoId="C" onSelect={clienteSel} url={edoGlobal.urlws + "/cliente/lista/" + empresa + "?filtro="} tema="ctes" />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td style={{ width: '700px' }}>
                                        <Input size="sm" value={nombreCte} />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td ><span>Codigo</span></td>
                                    <td style={{ width: '200px' }}>
                                        <InputGroup>
                                            <Input size="sm" type="text" value={codigo} onChange={e => setCodigo(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarArticulo(); }} />
                                            <InputGroupAddon addonType="append">
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" id="btnBuscarArtiC">
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                                <Popover trigger="legacy" placement="right" isOpen={popoverArtiOpen} target="btnBuscarArtiC" toggle={() => setPopoverArtiOpen(!popoverArtiOpen)} >
                                                    <PopoverBody>
                                                        <BuscadorArticulos onSelect={articuloSel} url={edoGlobal.urlws + "/articulo/listadepto/" + empresa + "?depto=" + depto + "&filtro="} />
                                                    </PopoverBody>
                                                </Popover>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td style={{ width: '700px' }}>
                                        <Input size="sm" value={nombreEst} />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td ><span>Medico</span></td>
                                    <td style={{ width: '100px' }}>
                                        <InputGroup>
                                            <Input size="sm" type="text" value={idMed} onChange={e => setIdMed(e.target.value)} onKeyPress={(e) => { if (e.which == 13) cargarMedico(); }} />
                                            <InputGroupAddon addonType="append">
                                                &nbsp;
                                                <Button outline size="sm" color="secondary" id="btnBuscarMedC">
                                                    <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                                </Button>
                                                <Popover trigger="legacy" placement="right" isOpen={popoverMedOpen} target="btnBuscarMedC" toggle={() => setPopoverMedOpen(!popoverMedOpen)} >
                                                    <PopoverBody>
                                                        <Buscador campoId="I" onSelect={medicoSel} url={edoGlobal.urlws + "/medico/lista/" + empresa + "?filtro="} tema="meds" />
                                                    </PopoverBody>
                                                </Popover>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </td>
                                    <td style={{ width: '700px' }}>
                                        <Input size="sm" value={nombreMed} />
                                    </td>
                                </tr>
                            </table>
                            <Row>
                                <div className='col-3'>
                                    <span>Tipo paciente</span>
                                    <Input size="sm" type="select" value={tipoPac} onChange={e => cambiarTipoPac(e.target.value)}>
                                                <option value={-1}>Especifique</option>
                                                {tiposPac.map((o, i) => <option key={i} value={o.I}>{o.N}</option>)}
                                    </Input>
                                    <span>{nomTipoPac}</span>
                                </div>
                                <div className='col'>
                                    <span>Observaciones</span>
                                    <Input type="textarea" size="sm" value={texto} onChange={e => setTexto(e.target.value) } />
                                </div>
                            </Row>
                            <br/>
                            <b>Indicaciones</b><br/>
                            <span>{descrip}</span>
                            <br/>
                            <br/>
                            <Button size="sm" color="success" onClick={guardar}>guardar</Button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <Button size="sm" color="danger" onClick={() => setEditando(false)}>Cancelar</Button>
                        </Panel>
                    </div>
                </div>
            </Row>
            <Modal size="lg" isOpen={selPac} toggle={() => setSelPac(false)}>
                <ModalHeader>
                    <span>Indique el paciente para cita a las {hora}</span>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="row">
                            <div className="col-2">
                                <FormGroup  >
                                    <span>Apellido Paterno</span>
                                    <Input size="sm" type="text" value={apePat} onChange={e => setApePat(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup  >
                                    <span>Apellido Materno</span>
                                    <Input size="sm" type="text" value={apeMat} onChange={e => setApeMat(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup  >
                                    <span>Nombre</span>
                                    <Input size="sm" type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
                                </FormGroup>
                            </div>
                            <div className="col-1">
                                <br />
                                <Button outline color="success" size="sm" onClick={cargarPacientes}><img src="imagenes/zoom.png" alt="buscar" /></Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal size="lg" isOpen={modalPac} toggle={() => setModalPac(false)}>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <Pacientes sel={true} pacSeleccionado={pacSeleccionado} empresa={empresa}/>
                </ModalBody>
            </Modal>
            {cargando ? (
          <div className="loader">
            <Spinner animation="border" color="warning" />
          </div>
        ) : null}
        </div>
    );
}