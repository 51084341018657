import React from 'react';
import { Input } from 'reactstrap';
import { Card, Button,CardBody } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export default class Login extends React.Component{
    static contextType = AppContext;
    constructor(props){
        super(props);
        this.state = {
            usu:'',
            pwd:'',
            emp:props.empresa
        };
    }
    componentDidMount() {
        const edoGlobal = this.context;
        if(edoGlobal.urlws === 'http://localhost:27893'){
            this.state.usu = 'cesar';
            this.state.pwd = 'Ce$aR';
        }
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    loguear  = async() => {
        if(this.state.emp == 0)
           return;
        if(this.state.emp == 1){
            Swal.fire('Atencion',"Analizate no esta disponible",'error');
            return;
        }
        const edoGlobal = this.context;
        const usu = this.state.usu;
        const pwd = this.state.pwd;
        const emp = this.state.emp;
        const url = `${edoGlobal.urlws}/usuario/iniciarSesion?usuario=${usu}&pwd=${pwd}&db=${emp}`;
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id === 0){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        res.Empresa = emp;
        this.props.iniciar(res);
    }
    render(){
        const edoGlobal = this.context;
        return(
            <Panel titulo={edoGlobal.nombreEmpresa}>
                <form >
                    {this.props.pedirEmpresa && <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Empresa</span>
                                <Input size="sm" type="select" className="form-control form-control-sm" name="emp" value={this.state.emp} onChange={this.onChange} >
                                    <option value={0}>Seleccione</option>
                                    <option value={2}>D.M.I. - Rosario</option>
                                    <option value={1}>D.M.I. - Jesus</option>
                                </Input>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Usuario</span>
                                <input size="sm" className="form-control form-control-sm" type="text" name="usu" value={this.state.usu} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Contraseña</span>
                                <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <Button color="success" size="sm" outline onClick={this.loguear}>Aceptar</Button>
                </form>
            </Panel>
        )
    }
}