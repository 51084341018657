import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { InputGroupText,InputGroup, Row, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';

export default function Cliente(props){
    const edoGlobal = useContext(AppContext);
    let [Nombre, setNombre] = useState('');
    let [Regimen, setRegimen] = useState('');
    let [ListaDMA, setListaDMA] = useState('A');
    let [Calle, setCalle] = useState('');
    let [Orientacion, setOrientacion] = useState('');
    let [NumeroExt, setNumeroExt] = useState('');
    let [NumeroInt, setNumeroInt] = useState('');
    let [Colonia, setColonia] = useState('');
    let [Rfc, setRfc] = useState('');
    let [Ciudad, setCiudad] = useState('');
    let [Municipio, setMunicipio] = useState('');
    let [Estado, setEstado] = useState('');
    let [Pais, setPais] = useState('');
    let [Telefono, setTelefono] = useState('');
    let [Celular, setCelular] = useState('');
    let [CP, setCP] = useState('');
    let [Email, setEmail] = useState('');
    let [NombreComercial, setNombreComercial] = useState('');
    let [ConCredito, setConCredito] = useState(false);
    let [Plazo, setPlazo] = useState(0);
    let [Limite, setLimite] = useState(0);
    let [Sector, setSector] = useState(1);
    let [PtjeRetIsr, setPtjeRetIsr] = useState(0);
    let [PtjeRetIva, setPtjeRetIva] = useState(0);
    let [Descuento, setDescuento] = useState(0);

    useEffect(() => {
        setNombre(props.cliente.Nombre);
        setRegimen(props.cliente.Regimen);
        setListaDMA(props.cliente.ListaDMA);
        setCalle(props.cliente.Calle);
        setOrientacion(props.cliente.Orientacion);
        setNumeroExt(props.cliente.NumeroExt);
        setNumeroInt(props.cliente.NumeroInt);
        setColonia(props.cliente.Colonia);
        setRfc(props.cliente.Rfc);
        setCiudad(props.cliente.Ciudad);
        setMunicipio(props.cliente.Municipio);
        setEstado(props.cliente.Estado);
        setPais(props.cliente.Pais);
        setTelefono(props.cliente.Telefono);
        setCelular(props.cliente.Celular);
        setCP(props.cliente.CP);
        setEmail(props.cliente.Email);
        setNombreComercial(props.cliente.NombreComercial);
        setConCredito(props.cliente.ConCredito);
        setPlazo(props.cliente.Plazo);
        setLimite(props.cliente.Limite);
        setSector(props.cliente.Sector);
        setPtjeRetIsr(props.cliente.PtjeRetIsr);
        setPtjeRetIva(props.cliente.PtjeRetIva);
        setDescuento(props.cliente.Descuento);
    }, [props.cliente]);

    async function guardar() {
        if (Nombre.length < 3) {
            Swal.fire('Atencion', 'Debe indicar el nombre del cliente', 'error');
            return;
        }
        const dato = {
            Clave:props.cliente.Clave, Nombre,Calle,Orientacion,NumeroExt,NumeroInt,
            Colonia,Rfc,Ciudad,Municipio,Estado,Pais,CP,Telefono,Celular,Email,
            ConCredito,Plazo,Sector,Limite,NombreComercial,PtjeRetIsr,PtjeRetIva,
            Regimen, ListaDMA, Descuento, Usuario:edoGlobal.usuario.Id
        }
        console.log(dato);
        let url = `${edoGlobal.urlws}/cliente/wsguardar?db=${props.empresa}`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res.substring(0,2) == "ok") {
            Swal.fire('Atencion', 'Datos guardados', 'success');
            props.cerrar(Number(res.substring(3)));
        }
        else
            Swal.fire('Atencion', res, 'error');
    }

    return (
        <div>
            <Row>
                <div className='col-9'>
                    <FormGroup >
                        <span>Nombre</span>
                        <Input size="sm" value={Nombre} onChange={e => setNombre(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-3'>
                    <FormGroup >
                        <span>R.F.C.</span>
                        <Input size="sm" value={Rfc} onChange={e => setRfc(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col'>
                    <FormGroup >
                        <span>Calle</span>
                        <Input size="sm" value={Calle} onChange={e => setCalle(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>No.Ext.</span>
                        <Input size="sm" value={NumeroExt} onChange={e => setNumeroExt(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>No.Int.</span>
                        <Input size="sm" value={NumeroInt} onChange={e => setNumeroInt(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col'>
                    <FormGroup >
                        <span>Colonia</span>
                        <Input size="sm" value={Colonia} onChange={e => setColonia(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup >
                        <span>Ciudad</span>
                        <Input size="sm" value={Ciudad} onChange={e => setCiudad(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup >
                        <span>Municipio</span>
                        <Input size="sm" value={Municipio} onChange={e => setMunicipio(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup >
                        <span>Estado</span>
                        <Input size="sm" value={Estado} onChange={e => setEstado(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-2'>
                    <FormGroup >
                        <span>C.P.</span>
                        <Input size="sm" value={CP} onChange={e => setCP(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-3'>
                    <FormGroup >
                        <span>Telefono</span>
                        <Input size="sm" value={Telefono} onChange={e => setTelefono(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-3'>
                    <FormGroup >
                        <span>Celular</span>
                        <Input size="sm" value={Celular} onChange={e => setCelular(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-1'>
                    <FormGroup >
                        <span>Credito</span><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input size="sm" type="checkbox" checked={ConCredito} onChange={e => setConCredito(e.target.checked)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>Limite</span>
                        <Input size="sm" value={Limite} onChange={e => setLimite(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col'>
                    <FormGroup >
                        <span>Nombre Comercial</span>
                        <Input size="sm" value={NombreComercial} onChange={e => setNombreComercial(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>Precios</span>
                        <Input size="sm" type="select" value={ListaDMA} onChange={e => setListaDMA(e.target.value)}>
                            <option value={""}>Especifique</option>
                            {props.listas.map((o,i)=><option key={i} value={o.I}>Lista {o.N}</option>)}
                        </Input>
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col'>
                    <FormGroup >
                        <span>Regimen</span>
                        <Input size="sm" type="select" value={Regimen} onChange={e => setRegimen(e.target.value)}>
                            <option value={""}>Especifique</option>
                            {props.regimenes.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                        </Input>
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-2'>
                    <FormGroup >
                        <span>% Descuento</span>
                        <Input size="sm"  value={Descuento}  />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>%Ret.IVA</span>
                        <Input size="sm"  value={PtjeRetIva} onChange={e => setPtjeRetIva(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>%Ret.ISR</span>
                        <Input size="sm" value={PtjeRetIsr} onChange={e => setPtjeRetIsr(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-2'></div>
                <div className='col-5'>
                    <Button size="sm" color="success" onClick={guardar}>Aceptar</Button>
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    <Button size="sm" color="danger" onClick={()=>props.cerrar(0)}>Cancelar</Button>
                </div>
            </Row>
        </div> 
    )
}