import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { InputGroupText,InputGroup, Row, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';

export default function Medico(props){
    const edoGlobal = useContext(AppContext);
    let [Nombre, setNombre] = useState('');
    let [ApePat, setApePat] = useState('');
    let [ApeMat, setApeMat] = useState('');
    let [Direccion, setDireccion] = useState('');
    let [Ciudad, setCiudad] = useState('');
    let [Telefono, setTelefono] = useState('');
    let [Email, setEmail] = useState('');
    let [Celular, setCelular] = useState('');
    let [Cedula, setCedula] = useState('');
    let [Cumple, setCumple] = useState('');
    let [NumeroExt, setNumeroExt] = useState('');
    let [NumeroInt, setNumeroInt] = useState('');
    let [Orientacion, setOrientacion] = useState('');
    let [CP, setCP] = useState('');
    let [Especialidad, setEspecialidad] = useState(0);

    useEffect(() => {
        setNombre(props.medico.Nombre);
        setApePat(props.medico.ApePat);
        setApeMat(props.medico.ApeMat);
        setDireccion(props.medico.Direccion);
        setCiudad(props.medico.Ciudad);
        setOrientacion(props.medico.Orientacion);
        setNumeroExt(props.medico.NumeroExt);
        setNumeroInt(props.medico.NumeroInt);
        setTelefono(props.medico.Telefono);
        setCelular(props.medico.Celular);
        setCP(props.medico.CP);
        setEmail(props.medico.Email);
        setCedula(props.medico.Cedula);
        setEspecialidad(props.medico.Especialidad);
        setCumple(props.medico.Cumple);
    }, [props.medico]);

    async function guardar() {
        if (Nombre.length < 3) {
            Swal.fire('Atencion', 'Debe indicar el nombre del medico', 'error');
            return;
        }
        const dato = {
            Clave:props.medico.Clave, Nombre, ApePat, ApeMat,
            Direccion,Orientacion,NumeroExt,NumeroInt,Ciudad,CP,
            Telefono,Celular,Email,Cedula,Cumple,Especialidad,
            Usuario:edoGlobal.usuario.Id, Empresa:props.empresa
        }
        console.log(dato);
        let url = `${edoGlobal.urlws}/medico/wsguardar`;
        props.setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        props.setCargando(false);
        if (res.substring(0,2) == "ok") {
            Swal.fire('Atencion', 'Datos guardados', 'success');
            props.cerrar(Number(res.substring(3)));
        }
        else
            Swal.fire('Atencion', res, 'error');
    }

    return (
        <div>
            <Row>
                <div className='col-4'>
                    <FormGroup >
                        <span>Ape.Paterno</span>
                        <Input size="sm" value={ApePat} onChange={e => setApePat(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-4'>
                    <FormGroup >
                        <span>Ape.Materno</span>
                        <Input size="sm" value={ApeMat} onChange={e => setApeMat(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-4'>
                    <FormGroup >
                        <span>Nombre</span>
                        <Input size="sm" value={Nombre} onChange={e => setNombre(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col'>
                    <FormGroup >
                        <span>Direccion</span>
                        <Input size="sm" value={Direccion} onChange={e => setDireccion(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>No.Ext.</span>
                        <Input size="sm" value={NumeroExt} onChange={e => setNumeroExt(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>No.Int.</span>
                        <Input size="sm" value={NumeroInt} onChange={e => setNumeroInt(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-3'>
                    <FormGroup >
                        <span>Ciudad</span>
                        <Input size="sm" value={Ciudad} onChange={e => setCiudad(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-2'>
                    <FormGroup >
                        <span>C.P.</span>
                        <Input size="sm" value={CP} onChange={e => setCP(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>Telefono</span>
                        <Input size="sm" value={Telefono} onChange={e => setTelefono(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col-2'>
                    <FormGroup >
                        <span>Celular</span>
                        <Input size="sm" value={Celular} onChange={e => setCelular(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup >
                        <span>Email</span>
                        <Input size="sm" value={Email} onChange={e => setEmail(e.target.value)} />
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-3'>
                    <FormGroup >
                        <span>Cedula</span>
                        <Input size="sm" value={Cedula} onChange={e => setCedula(e.target.value)} />
                    </FormGroup>
                </div>
                <div className='col'>
                    <FormGroup >
                        <span>Especialidad</span>
                        <Input size="sm" type="select" value={Especialidad} onChange={e => setEspecialidad(e.target.value)}>
                            {props.especialidades.map((o,i)=><option key={i} value={o.I}>{o.N}</option>)}
                        </Input>
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className='col-2'></div>
                <div className='col-5'>
                    <Button size="sm" color="success" onClick={guardar}>Aceptar</Button>
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;
                    <Button size="sm" color="danger" onClick={()=>props.cerrar(0)}>Cancelar</Button>
                </div>
            </Row>
        </div> 
    )
}